import * as React from "react"
import Layout from "../components/layout"
import { Container, Section } from "../components/ui"

export default function Privacy() {
  return (
    <Layout>
      <Section>
        <Container>
          <h1>Privacy Policy</h1>
        </Container>
      </Section>
    </Layout>
  )
}
